export function LifeProofErrorStep({ errorLink }: { errorLink: string }) {
  return (
    <div className='flex items-center justify-center flex-col mt-20 mb-20'>
      <p className='text-black-800 text-sm'>
        Para finalizar o processo, é necessário que você valide a titularidade
        de sua conta.
      </p>
      <a
        href={errorLink}
        target='blank'
        className='w-[224px] h-40 bg-secondary-500 text-white-500 font-bold rounded-4 mt-12 p-8 block text-center uppercase'
      >
        CLIQUE AQUI
      </a>
    </div>
  )
}
