import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ExpectedError, LifeProofError } from 'errors'
import { useToast } from 'providers/Toast'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Account } from 'services/customer/account'
import { PostRecoveryPasswordResponseProps } from 'services/customer/account/account.types'
import { Notifications } from 'services/notifications/notifications'
import { cpfOrCpnjMask, removeCpfMask } from 'utils/functions'
import { handleStore } from 'utils/handleStore'
import { useLoginStore } from '../FormLoginContainer.store'
import { passwordRecoveryModalSchema } from './PasswordRecoveryModal.schema'
import { usePasswordRecoveryModalStore } from './PasswordRecoveryModal.store'
import {
  MethodSenderMfaCodeEnum,
  PasswordRecoveryModalValues,
  RecoveryStepEnum
} from './PasswordRecoveryModal.types'

export function usePasswordRecoveryModal({
  setTitleModal
}: {
  setTitleModal: Dispatch<SetStateAction<string>>
}) {
  const { showError } = useToast()
  const { setEnableEmailButton, setEnableSmsButton, setButtonIsLoading } =
    usePasswordRecoveryModalStore()

  const [passwordRecoveryStep, setPasswordRecoveryStep] =
    useState<RecoveryStepEnum>(RecoveryStepEnum.DOCUMENT_STEP)
  const [customerData, setCustomerData] =
    useState<PostRecoveryPasswordResponseProps>({
      customerEmail: '',
      customerPhone: ''
    })
  const [methodUsed, setMethodUsed] =
    useState<MethodSenderMfaCodeEnum>(undefined)

  const form = useForm<PasswordRecoveryModalValues>({
    resolver: zodResolver(passwordRecoveryModalSchema),
    mode: 'onChange',
    defaultValues: {
      customerDocument: ''
    }
  })

  const { setLoginFormErrors, loginFormErrors } = useLoginStore()
  const onWatchCustomerDocument = form.watch('customerDocument')

  const { data, isLoading } = useQuery({
    queryKey: ['fetchAttempts'],
    queryFn: async () => {
      const serviceNotifications = new Notifications()
      return await serviceNotifications.FetchAttemptsToSendRecoveryPasswordMessage(
        {
          customerDocument: removeCpfMask(onWatchCustomerDocument)
        }
      )
    },
    enabled: passwordRecoveryStep === 'choiceStep'
  })

  const { mutateAsync: sendDocumentToRecoveryPassword, isPending } =
    useMutation({
      mutationFn: async (document: string) => {
        const serviceCustomer = new Account()

        return await serviceCustomer.PostRecoveryPassword({
          customerDocument: removeCpfMask(document)
        })
      },
      onSuccess(data) {
        if (data) {
          setCustomerData({
            customerEmail: data.customerEmail,
            customerPhone: data.customerPhone
          })
          setPasswordRecoveryStep(RecoveryStepEnum.METHOD_CHOICE_STEP)
        } else {
          showError('Documento não registrado.')
        }
      },
      onError(error) {
        setTitleModal('Ops...')
        if (error instanceof LifeProofError) {
          setPasswordRecoveryStep(RecoveryStepEnum.LIFE_PROOF_ERROR_STEP)
          setLoginFormErrors({
            ...loginFormErrors,
            errorMessage: error.message,
            errorClick: true,
            errorFunction: 'lifeProof',
            errorLink: error.urlLinkLifeProof,
            loginType: 'form'
          })
        }

        if (error instanceof ExpectedError) {
          showError(error.message)
        }
      }
    })

  const { mutateAsync: sendMfaCodeActivation } = useMutation({
    mutationFn: async ({
      methodChoosed
    }: { methodChoosed: MethodSenderMfaCodeEnum }) => {
      setButtonIsLoading(true)
      const serviceNotifications = new Notifications()

      return await serviceNotifications.SendLinkToRecoveryPassword({
        customerDocument: removeCpfMask(onWatchCustomerDocument),
        sendMethod: methodChoosed,
        storeCode: handleStore()
      })
    },
    onSuccess(data) {
      setCustomerData({
        customerEmail: data.customerEmail,
        customerPhone: data.customerPhone
      })

      setPasswordRecoveryStep(RecoveryStepEnum.SUCCESS_STEP)
    },
    onSettled(data, error) {
      if (data || error) {
        setButtonIsLoading(false)
      }
    },
    onError(error) {
      if (error instanceof ExpectedError) {
        showError(error.message)
      }
    }
  })

  const onSubmit = async ({
    customerDocument
  }: { customerDocument: string }) => {
    return await sendDocumentToRecoveryPassword(customerDocument)
  }

  async function chooseMfaMethodSender(mfaType: MethodSenderMfaCodeEnum) {
    setMethodUsed(mfaType)

    return await sendMfaCodeActivation({
      methodChoosed: mfaType
    })
  }

  const shouldDisableDocumentButton = useMemo(() => {
    return isPending || !!form.formState.errors.customerDocument
  }, [isPending, form.formState])

  useEffect(() => {
    if (onWatchCustomerDocument)
      form.setValue(
        'customerDocument',
        cpfOrCpnjMask(onWatchCustomerDocument),
        {
          shouldValidate: true
        }
      )
    setTitleModal('ESQUECEU SUA SENHA DE ACESSO?')
  }, [onWatchCustomerDocument])

  useEffect(() => {
    if (data) {
      setEnableEmailButton(!data.remainingAttempts[0].enabled)
      setEnableSmsButton(!data.remainingAttempts[1].enabled)
    }

    if (isPending || isLoading) {
      setEnableEmailButton(true)
      setEnableSmsButton(true)
    }
  }, [data, isPending, isLoading])

  return {
    passwordRecoveryStep,
    passwordRecoveryModalSchema,
    form,
    onSubmit,
    isPending,
    customerData,
    chooseMfaMethodSender,
    methodUsed,
    loginFormErrors,
    shouldDisableDocumentButton
  }
}
