import { useEffect, useRef, useState } from 'react'

import {
  dataLayerEventPromotionClick,
  dataLayerEventPromotionView,
  formatBannerData,
  sendBeaconData,
  useUser
} from '@k_frontend/core'
import { useBannerMonetizaLogin } from 'hooks/useBannerMonetiza'
import { useVisibility } from 'hooks/useVisibility'

export function useBanner() {
  const bannerRef = useRef(null)
  const { session, clientId } = useUser()
  const isVisible = useVisibility(bannerRef, { threshold: 0.5 })
  const { data: bannerData, isLoading } = useBannerMonetizaLogin(session)
  const [wasPrinted, setWasPrinted] = useState(false)

  const beaconData = {
    user_id: clientId,
    session_id: session
  }

  const banner = bannerData?.login?.[0]

  const payloadFormatted = formatBannerData(
    banner?.title || banner?.campaignName,
    1,
    'tema_login_1'
  )

  const dataLayerBannerClick = () => {
    dataLayerEventPromotionClick(payloadFormatted)
    const url = banner?.clickUrl
    sendBeaconData(url, beaconData)
  }

  useEffect(() => {
    if (banner && !wasPrinted) {
      dataLayerEventPromotionView(payloadFormatted)
      sendBeaconData(banner?.impressionUrl, beaconData)
    }
    if (isVisible && !wasPrinted) {
      sendBeaconData(banner?.viewUrl, beaconData)
      setWasPrinted(true)
    }
  }, [isVisible, wasPrinted])

  return {
    banner,
    bannerRef,
    dataLayerBannerClick,
    isLoading
  }
}
