import { useGoogleLogin } from '@react-oauth/google'
import { useCallback, useEffect } from 'react'
import { LoginWithSocialAccountProps } from './LoginWithSocialAccount.types'

import { dataLayerEventLogin, useUser, useVisitor } from '@k_frontend/core'
import { useMutation } from '@tanstack/react-query'
import {
  ExpectedError,
  LifeProofError,
  NonExistentRegistration,
  RecoveryAccountError
} from 'errors'
import { useReCaptcha } from 'next-recaptcha-v3'
import { useRouter } from 'next/router'
import { useToast } from 'providers/Toast'
import { getParamsFromQueryString } from 'services/listing'
import { Login } from 'services/login/login'
import Kernel from 'settings/kernel'
import { handleStore } from 'utils/handleStore'
import { redirect } from 'utils/redirect'
import { getSessionIdTmxStorage } from 'utils/sessionIdTmxStorage'
import { useLoginStore } from '../FormLoginContainer.store'
import { LoginWithSocialAccountHookProps } from './LoginWithSocialAccount.types'

export function useLoginWithSocialAccount({
  concretizeLogin,
  setAgreementModalOpen,
  setConcretizeLoginProps
}: LoginWithSocialAccountHookProps) {
  const { init } = useVisitor()
  const { showError } = useToast()
  const { setClientName, setClientId, setIsPrime, setSession } = useUser()
  const {
    setIsOpenAccountRecoveryModal,
    loginFormErrors,
    setLoginFormErrors,
    appleAccessToken,
    setAppleAccessToken
  } = useLoginStore()
  const router = useRouter()
  const { reCaptchaKey } = useReCaptcha()

  const { mutateAsync } = useMutation({
    mutationFn: async ({
      socialNetwork,
      tokenNetwork
    }: LoginWithSocialAccountProps) => {
      const serviceLogin = new Login()

      return await serviceLogin.doLoginWithSocialAccount({
        accessToken: tokenNetwork,
        captcha: reCaptchaKey,
        socialAccountNetwork: socialNetwork,
        storeCode: handleStore(),
        tmxSession: getSessionIdTmxStorage()
      })
    }
  })

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const {
          customerId,
          customerSession,
          firstName,
          isPrimeCustomer,
          maxAgeCookie,
          needToUpdateData,
          updateConsentForm,
          customerEmail
        } = await mutateAsync({
          socialNetwork: 'google',
          tokenNetwork: codeResponse.access_token
        })

        if (customerId) {
          await init(Kernel, customerId, customerSession)
        }

        if (!updateConsentForm)
          return concretizeLogin({
            customerId,
            customerSession,
            firstName,
            isPrimeCustomer,
            maxAgeCookie,
            needToUpdateData
          })

        setConcretizeLoginProps({
          customerId,
          customerSession,
          firstName,
          isPrimeCustomer,
          maxAgeCookie,
          needToUpdateData,
          customerEmail
        })
        setAgreementModalOpen(true)
      } catch (error) {
        if (error instanceof ExpectedError) {
          setLoginFormErrors({
            ...loginFormErrors,
            errorMessage: error.message,
            errorClick: false,
            errorFunction: 'genericError',
            loginType: 'social'
          })

          showError(error.message)
        }

        if (error instanceof LifeProofError) {
          setLoginFormErrors({
            ...loginFormErrors,
            errorMessage: error.message,
            errorClick: true,
            errorFunction: 'lifeProof',
            errorLink: error.urlLinkLifeProof,
            loginType: 'social'
          })
        }

        if (error instanceof RecoveryAccountError) {
          setLoginFormErrors({
            ...loginFormErrors,
            userLogin: error.email,
            userPhone: error.mobilePhone,
            errorMessage: error.message,
            errorClick: true,
            errorFunction: 'inactiveAccount',
            recoveryHashLogin: error.recoveryHash,
            loginType: 'social'
          })

          setIsOpenAccountRecoveryModal(true)
          return
        }

        if (error instanceof NonExistentRegistration) {
          showError(error.message)

          setTimeout(() => {
            router.push('/cadastro')
          }, 3000)
        }
      }
    },
    onError: () =>
      showError(
        'Ocorreu um erro ao tentar acessar sua conta KaBuM!, com sua conta Google'
      )
  })

  const callbackSuccessApple = useCallback(async () => {
    if (appleAccessToken)
      try {
        const {
          customerId,
          customerSession,
          firstName,
          isPrimeCustomer,
          maxAgeCookie,
          needToUpdateData
        } = await mutateAsync({
          socialNetwork: 'apple',
          tokenNetwork: appleAccessToken
        })

        if (customerId) {
          await init(Kernel, customerId, customerSession)
        }

        setClientName(firstName)
        setClientId(customerId, maxAgeCookie)
        setSession(customerSession, maxAgeCookie)

        if (isPrimeCustomer > 0) {
          setIsPrime(true, maxAgeCookie)
        }

        if (needToUpdateData) {
          localStorage.setItem('needsUpdatePJ', 'true')
        }

        dataLayerEventLogin('KaBuM!')

        redirect(router)
      } catch (error) {
        if (error instanceof ExpectedError) {
          setLoginFormErrors({
            ...loginFormErrors,
            errorMessage: error.message,
            errorClick: false,
            errorFunction: 'genericError',
            loginType: 'social'
          })

          showError(error.message)
        }

        if (error instanceof LifeProofError) {
          setLoginFormErrors({
            ...loginFormErrors,
            errorMessage: error.message,
            errorClick: true,
            errorFunction: 'lifeProof',
            errorLink: error.urlLinkLifeProof,
            loginType: 'social'
          })
        }

        if (error instanceof RecoveryAccountError) {
          setLoginFormErrors({
            ...loginFormErrors,
            userLogin: error.email,
            userPhone: error.mobilePhone,
            errorMessage: error.message,
            errorClick: true,
            errorFunction: 'inactiveAccount',
            recoveryHashLogin: error.recoveryHash,
            loginType: 'social'
          })

          setIsOpenAccountRecoveryModal(true)
          return
        }

        if (error instanceof NonExistentRegistration) {
          showError(error.message)

          setTimeout(() => {
            router.push('/cadastro')
          }, 3000)
        }
      }
  }, [appleAccessToken])

  useEffect(() => {
    const { access_token, scope } = getParamsFromQueryString(
      window.location.search
    )

    if (scope === 'login_apple' && !!access_token) {
      setAppleAccessToken(access_token)
      callbackSuccessApple()
    }
  }, [appleAccessToken === undefined || appleAccessToken === ''])

  return {
    googleLogin
  }
}
