import { Dispatch, SetStateAction, useMemo } from 'react'

import { KLoader } from 'components/Loader'
import { ChoiceStep } from './ChoiceStep'
import { DocumentStep } from './DocumentStep'
import { LifeProofErrorStep } from './LifeProofErrorStep'
import { usePasswordRecoveryModal } from './PasswordRecoveryModal.hook'
import { RecoveryStepEnum } from './PasswordRecoveryModal.types'
import { SuccessStep } from './SuccessStep'

export function PasswordRecoveryModal({
  setIsOpenRecoveryPasswordModal,
  setTitlePasswordRecoveryModal
}: {
  setIsOpenRecoveryPasswordModal: Dispatch<SetStateAction<boolean>>
  setTitlePasswordRecoveryModal: Dispatch<SetStateAction<string>>
}) {
  const {
    passwordRecoveryStep,
    form,
    onSubmit,
    isPending,
    customerData,
    chooseMfaMethodSender,
    methodUsed,
    loginFormErrors,
    shouldDisableDocumentButton
  } = usePasswordRecoveryModal({ setTitleModal: setTitlePasswordRecoveryModal })

  const renderPasswordRecoveryStep = useMemo(() => {
    if (isPending) {
      return <KLoader />
    }

    if (passwordRecoveryStep === RecoveryStepEnum.DOCUMENT_STEP) {
      return (
        <DocumentStep
          form={form}
          shouldDisableDocumentButton={shouldDisableDocumentButton}
        />
      )
    }

    if (passwordRecoveryStep === RecoveryStepEnum.LIFE_PROOF_ERROR_STEP) {
      return <LifeProofErrorStep errorLink={loginFormErrors.errorLink} />
    }

    if (passwordRecoveryStep === RecoveryStepEnum.METHOD_CHOICE_STEP) {
      return (
        <ChoiceStep
          customerPhone={customerData.customerPhone}
          customerEmail={customerData.customerEmail}
          sendMfaMethod={chooseMfaMethodSender}
        />
      )
    }

    if (passwordRecoveryStep === RecoveryStepEnum.SUCCESS_STEP) {
      return (
        <SuccessStep
          customerPhone={customerData.customerPhone}
          customerEmail={customerData.customerEmail}
          methodUsed={methodUsed}
          setIsOpenRecoveryPasswordModal={setIsOpenRecoveryPasswordModal}
        />
      )
    }
  }, [passwordRecoveryStep, isPending, shouldDisableDocumentButton, form])

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className='flex flex-col justify-center items-center'>
        {renderPasswordRecoveryStep}
      </div>
    </form>
  )
}
