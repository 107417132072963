import { HttpAxiosClient } from 'adapters/httpAxiosClient/httpAxiosClient'
import { HttpRequestError } from 'errors/HttpRequestError'
import { CUSTOMER } from 'settings/endpoints'
import {
  PostActivateCustomerAccountProps,
  PostRecoveryPasswordProps,
  PostRecoveryPasswordResponseProps
} from './account.types'

import {
  ExpectedError,
  LifeProofError,
  NonExistentRegistration,
  RecoveryAccountError
} from 'errors'

export class Account {
  constructor(public readonly httpClient = new HttpAxiosClient()) {}

  async PostActivateCustomerAccount({
    mfaConfirmationCode,
    recoveryHash
  }: PostActivateCustomerAccountProps): Promise<{ message: string }> {
    try {
      const { body } = await this.httpClient.request({
        method: 'put',
        url: CUSTOMER.ACCOUNT_ACTIVATE,
        body: {
          recovery_hash: recoveryHash,
          activation_code: mfaConfirmationCode
        }
      })

      return {
        message: body.message
      }
    } catch (error: unknown) {
      if (error instanceof HttpRequestError) {
        throw new ExpectedError(error.body.message, error.statusCode)
      }
    }
  }

  async PostRecoveryPassword({
    customerDocument
  }: PostRecoveryPasswordProps): Promise<PostRecoveryPasswordResponseProps> {
    try {
      const { body } = await this.httpClient.request({
        method: 'post',
        url: CUSTOMER.RECOVERY_PASSWORD,
        body: {
          customer_cpf_cnpj: customerDocument
        }
      })

      return {
        customerEmail: body.customer_email,
        customerPhone: body.customer_cellphone
      }
    } catch (error: unknown) {
      if (error instanceof HttpRequestError) {
        if (error.body?.error_code === RecoveryAccountError.errorCode) {
          throw new RecoveryAccountError(
            error.body.message,
            error.body.customer_cellphone,
            error.body.customer_email,
            error.body.recovery_hash
          )
        }

        if (error.body?.error_code === LifeProofError.errorCode) {
          throw new LifeProofError(
            error.body.message,
            error.body.link_validacao_titularidade
          )
        }

        if (error.body?.error_code === NonExistentRegistration.errorCode) {
          throw new NonExistentRegistration(
            error.body.message,
            error.statusCode
          )
        }

        throw new ExpectedError(error.body.message, error.statusCode)
      }
    }
  }
}
