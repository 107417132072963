import { zodResolver } from '@hookform/resolvers/zod'
import { dataLayerEventLogin, useUser, useVisitor } from '@k_frontend/core'
import { useMutation } from '@tanstack/react-query'
import {
  ExpectedError,
  LifeProofError,
  NonExistentRegistration,
  RecoveryAccountError
} from 'errors'
import { useReCaptcha } from 'next-recaptcha-v3'
import { useRouter } from 'next/router'
import { useToast } from 'providers/Toast'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Login } from 'services/login/login'
import Kernel from 'settings/kernel'
import { handleStore } from 'utils/handleStore'
import { redirect } from 'utils/redirect'
import { getSessionIdTmxStorage } from 'utils/sessionIdTmxStorage'
import { formLoginSchema } from './FormLoginContainer.schema'
import { useLoginStore } from './FormLoginContainer.store'
import {
  ConcretizeLoginProps,
  FormLoginValues,
  LoginFormProps
} from './FormLoginContainer.types'

export function useFormLogin() {
  const router = useRouter()
  const { executeRecaptcha } = useReCaptcha()
  const {
    setLogin,
    setPassword,
    setButtonIsLoading,
    loginFormErrors,
    setLoginFormErrors,
    isOpenAccountRecoveryModal,
    setIsOpenAccountRecoveryModal,
    isOpenRecoveryPasswordModal,
    setIsOpenRecoveryPasswordModal,
    loginPasswordVisible,
    setLoginPasswordVisible
  } = useLoginStore()
  const { setClientName, setClientId, setIsPrime, setSession } = useUser()
  const { init } = useVisitor()
  const { showError } = useToast()
  const [titlePasswordRecoveryModal, setTitlePasswordRecoveryModal] =
    useState<string>('ESQUECEU SUA SENHA DE ACESSO?')
  const [concretizeLoginProps, setConcretizeLoginProps] = useState(
    {} as ConcretizeLoginProps
  )
  const [agreementModalOpen, setAgreementModalOpen] = useState(false)

  const form = useForm<FormLoginValues>({
    resolver: zodResolver(formLoginSchema),
    mode: 'onChange'
  })

  const handleChangeLoginInput = useCallback(
    (event) => {
      form.setValue('login', event.target.value.replace(/\s/g, ''))
    },
    [form]
  )

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async ({ login, password }: LoginFormProps) => {
      const captchaToken = await executeRecaptcha('login')
      const serviceLogin = new Login()
      return await serviceLogin.doLogin({
        password: password,
        login: login,
        captcha: captchaToken,
        tmxSession: getSessionIdTmxStorage(),
        storeCode: handleStore()
      })
    }
  })

  const concretizeLogin = useCallback(
    ({
      firstName,
      customerId,
      customerSession,
      isPrimeCustomer,
      needToUpdateData,
      maxAgeCookie
    }: ConcretizeLoginProps) => {
      setClientName(firstName)
      setClientId(customerId, maxAgeCookie)
      setSession(customerSession, maxAgeCookie)

      if (isPrimeCustomer > 0) {
        setIsPrime(true, maxAgeCookie)
      }

      if (needToUpdateData) {
        localStorage.setItem('needsUpdatePJ', 'true')
      }

      dataLayerEventLogin('KaBuM!')

      redirect(router)
    },
    [
      setClientName,
      setClientId,
      setSession,
      setIsPrime,
      dataLayerEventLogin,
      redirect
    ]
  )

  const onSubmit = async ({ login, password }: FormLoginValues) => {
    setPassword(password)
    setLogin(login)
    setButtonIsLoading(true)

    try {
      const {
        customerId,
        customerSession,
        firstName,
        isPrimeCustomer,
        maxAgeCookie,
        needToUpdateData,
        updateConsentForm,
        customerEmail
      } = await mutateAsync({ login, password })

      if (customerId) {
        await init(Kernel, customerId, customerSession)
      }

      if (!updateConsentForm)
        return concretizeLogin({
          customerId,
          customerSession,
          firstName,
          isPrimeCustomer,
          maxAgeCookie,
          needToUpdateData
        })

      setConcretizeLoginProps({
        customerId,
        customerSession,
        firstName,
        isPrimeCustomer,
        maxAgeCookie,
        needToUpdateData,
        customerEmail
      })
      setAgreementModalOpen(true)
    } catch (error) {
      if (error instanceof ExpectedError) {
        setLoginFormErrors({
          ...loginFormErrors,
          errorMessage: error.message,
          errorClick: false,
          errorFunction: 'genericError',
          loginType: 'form'
        })

        showError(error.message)
      }

      if (error instanceof LifeProofError) {
        setLoginFormErrors({
          ...loginFormErrors,
          errorMessage: error.message,
          errorClick: true,
          errorFunction: 'lifeProof',
          errorLink: error.urlLinkLifeProof,
          loginType: 'form'
        })
      }

      if (error instanceof RecoveryAccountError) {
        setLoginFormErrors({
          ...loginFormErrors,
          userLogin: error.email,
          userPhone: error.mobilePhone,
          errorMessage: error.message,
          errorClick: true,
          errorFunction: 'inactiveAccount',
          recoveryHashLogin: error.recoveryHash,
          loginType: 'form'
        })

        setIsOpenAccountRecoveryModal(true)
        return
      }

      if (error instanceof NonExistentRegistration) {
        showError(error.message)

        setTimeout(() => {
          router.push('/cadastro')
        }, 3000)
      }
    } finally {
      setButtonIsLoading(false)
    }
  }

  return {
    form,
    onSubmit,
    loginFormErrors,
    isOpenAccountRecoveryModal,
    setIsOpenAccountRecoveryModal,
    isOpenRecoveryPasswordModal,
    setIsOpenRecoveryPasswordModal,
    loginPasswordVisible,
    setLoginPasswordVisible,
    isPending,
    titlePasswordRecoveryModal,
    setTitlePasswordRecoveryModal,
    concretizeLoginProps,
    concretizeLogin,
    agreementModalOpen,
    setAgreementModalOpen,
    setConcretizeLoginProps,
    handleChangeLoginInput
  }
}
