import { create } from 'zustand'
import { LoginStoreProps } from './FormLoginContainer.types'

export const useLoginStore = create<LoginStoreProps>((set) => ({
  login: undefined,
  password: undefined,
  buttonIsLoading: false,
  loginFormErrors: {
    errorClick: false,
    errorFunction: undefined,
    errorMessage: undefined,
    errorLink: undefined,
    recoveryHashLogin: undefined,
    userLogin: undefined,
    userPhone: undefined,
    loginType: undefined
  },
  isOpenAccountRecoveryModal: false,
  isOpenRecoveryPasswordModal: false,
  loginPasswordVisible: false,
  socialLoginErrors: {
    errorClick: false,
    errorFunction: undefined,
    errorMessage: undefined,
    errorLink: undefined,
    recoveryHashLogin: undefined,
    userLogin: undefined,
    userPhone: undefined,
    loginType: undefined
  },
  appleAccessToken: undefined,
  setLogin: (login) => set({ login }),
  setPassword: (password) => set({ password }),
  setButtonIsLoading: (buttonIsLoading) => set({ buttonIsLoading }),
  setLoginFormErrors: (loginFormErrors) => set({ loginFormErrors }),
  setIsOpenAccountRecoveryModal: (isOpenAccountRecoveryModal) =>
    set({ isOpenAccountRecoveryModal }),
  setIsOpenRecoveryPasswordModal: (isOpenRecoveryPasswordModal) =>
    set({ isOpenRecoveryPasswordModal }),
  setLoginPasswordVisible: (loginPasswordVisible) =>
    set({ loginPasswordVisible }),
  setSocialLoginErrors: (socialLoginErrors) => set({ socialLoginErrors }),
  setAppleAccessToken: (appleAccessToken) => set({ appleAccessToken })
}))
